<template>
    <div class="combo" :class="{ open: isOpen }">
      <div v-if="label" :id="`${id}-combo-label`" class="combo-label" @click="focusCombo">{{ label }}</div>
      <div
        :id="`${id}-combo`"
        class="combo-input"
        role="combobox"
        tabindex="0"
        ref="combo"
        :aria-controls="`${id}-listbox`"
        :aria-expanded="isOpen"
        :aria-haspopup="`${id}-listbox`"
        :aria-labelledby="`${id}-combo-label`"
        @blur="onComboBlur"
        @click="toggleMenu"
        @keydown="onComboKeyDown"
      >
        {{ optionLabel ? value[optionLabel] : value }}
      </div>
      <div
        class="combo-menu"
        role="listbox"
        ref="listbox"
        :id="`${id}-listbox`"
        :aria-labelledby="`${id}-combo-label`"
        tabindex="-1"
        @focusout="onComboBlur"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          :id="`${id}-combo-${index}`"
          role="option"
          :class="['combo-option', { 'option-current': index === activeIndex }]"
          :aria-selected="index === activeIndex"
          @click="selectOption(index)"
          @mousedown="onOptionMouseDown"
        >
          {{ optionLabel ? option[optionLabel] : option }}
        </div>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'FilterSelect',
  model: {
    prop: 'value',
    event: 'input'
  },
  props:[
    'id',
    'options',
    'value',
    'label',
    'optionLabel',
    'optionValue',
  ],
  data() {
    return {
      activeIndex: 0,
      isOpen: false,
      searchString: '',
      searchTimeout: null,
      ignoreBlur: false,
    };
  },
  computed: {
    selectedOption() {
      return this.options[this.activeIndex];
    },
  },
  methods: {
    focusCombo() {
      this.$refs.combo.focus();
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    onComboBlur(event) {
      if (this.ignoreBlur) {
        this.ignoreBlur = false;
        return;
      }
      if (!this.$refs.listbox.contains(event.relatedTarget)) {
        this.isOpen = false;
      }
    },
    onComboKeyDown(event) {
      const action = this.getActionFromKey(event, this.isOpen);
      const max = this.options.length - 1;
      switch (action) {
        case 'Last':
        case 'First':
          this.isOpen = true;
        // intentional fallthrough
        case 'Next':
        case 'Previous':
        case 'PageUp':
        case 'PageDown':
          event.preventDefault();
          this.onOptionChange(this.getUpdatedIndex(this.activeIndex, max, action));
          break;
        case 'CloseSelect':
          event.preventDefault();
          this.selectOption(this.activeIndex);
        // intentional fallthrough
        case 'Close':
          event.preventDefault();
          this.isOpen = false;
          break;
        case 'Type':
          this.onComboType(event.key);
          break;
        case 'Open':
          event.preventDefault();
          this.isOpen = true;
          break;
      }
    },
    getActionFromKey(event, menuOpen) {
      const { key, altKey, ctrlKey, metaKey } = event;
      const openKeys = ['ArrowDown', 'ArrowUp', 'Enter', ' '];
      if (!menuOpen && openKeys.includes(key)) {
        return 'Open';
      }
      if (key === 'Home') {
        return 'First';
      }
      if (key === 'End') {
        return 'Last';
      }
      if (key === 'Backspace' || key === 'Clear' || (key.length === 1 && key !== ' ' && !altKey && !ctrlKey && !metaKey)) {
        return 'Type';
      }
      if (menuOpen) {
        if (key === 'ArrowUp' && altKey) {
          return 'CloseSelect';
        } else if (key === 'ArrowDown' && !altKey) {
          return 'Next';
        } else if (key === 'ArrowUp') {
          return 'Previous';
        } else if (key === 'PageUp') {
          return 'PageUp';
        } else if (key === 'PageDown') {
          return 'PageDown';
        } else if (key === 'Escape') {
          return 'Close';
        } else if (key === 'Enter' || key === ' ') {
          return 'CloseSelect';
        }
      }
    },
    onComboType(letter) {
      this.isOpen = true;
      const searchString = this.getSearchString(letter);
      const searchIndex = this.getIndexByLetter(this.options, searchString, this.activeIndex + 1);
      if (searchIndex >= 0) {
        this.onOptionChange(searchIndex);
      } else {
        window.clearTimeout(this.searchTimeout);
        this.searchString = '';
      }
    },
    getSearchString(char) {
      if (typeof this.searchTimeout === 'number') {
        window.clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = window.setTimeout(() => {
        this.searchString = '';
      }, 500);
      this.searchString += char;
      return this.searchString;
    },
    getIndexByLetter(options, filter, startIndex = 0) {
      const orderedOptions = [...options.slice(startIndex), ...options.slice(0, startIndex)];
      const firstMatch = this.filterOptions(orderedOptions, filter)[0];
      const allSameLetter = array => array.every(letter => letter === array[0]);
      if (firstMatch) {
        return options.indexOf(firstMatch);
      } else if (allSameLetter(filter.split(''))) {
        const matches = this.filterOptions(orderedOptions, filter[0]);
        return options.indexOf(matches[0]);
      } else {
        return -1;
      }
    },
    filterOptions(options, filter, exclude = []) {
      return options.filter(option => {
        const matches = option.toLowerCase().indexOf(filter.toLowerCase()) === 0;
        return matches && !exclude.includes(option);
      });
    },
    getUpdatedIndex(currentIndex, maxIndex, action) {
      const pageSize = 10;
      switch (action) {
        case 'First':
          return 0;
        case 'Last':
          return maxIndex;
        case 'Previous':
          return Math.max(0, currentIndex - 1);
        case 'Next':
          return Math.min(maxIndex, currentIndex + 1);
        case 'PageUp':
          return Math.max(0, currentIndex - pageSize);
        case 'PageDown':
          return Math.min(maxIndex, currentIndex + pageSize);
        default:
          return currentIndex;
      }
    },
    onOptionChange(index) {
      this.activeIndex = index;
      this.$refs.combo.setAttribute('aria-activedescendant', `combo-${index}`);
      const options = this.$refs.listbox.querySelectorAll('[role=option]');
      options.forEach(optionEl => {
        optionEl.classList.remove('option-current');
      });
      options[index].classList.add('option-current');
      if (this.isScrollable(this.$refs.listbox)) {
        this.maintainScrollVisibility(options[index], this.$refs.listbox);
      }
      if (!this.isElementInView(options[index])) {
        options[index].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    },
    selectOption(index) {
      this.activeIndex = index;
      this.$emit('input', this.selectedOption);
      this.isOpen = false;
    },
    onOptionMouseDown() {
      this.ignoreBlur = true;
    },
    isScrollable(element) {
      return element && element.clientHeight < element.scrollHeight;
    },
    maintainScrollVisibility(activeElement, scrollParent) {
      const { offsetHeight, offsetTop } = activeElement;
      const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;
      const isAbove = offsetTop < scrollTop;
      const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;
      if (isAbove) {
        scrollParent.scrollTo(0, offsetTop);
      } else if (isBelow) {
        scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
      }
    },
    isElementInView(element) {
      const bounding = element.getBoundingClientRect();
      return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.combo *,
.combo *::before,
.combo *::after {
  box-sizing: border-box;
}

.combo {
  display: block;
  position: relative;
}

.combo::after {
  border-bottom: 1px solid #c7c7c7;
  border-right: 1px solid #c7c7c7;
  content: "";
  display: block;
  height: 8px;
  pointer-events: none;
  position: absolute;
  right: 16px;
  top: 45%;
  transform: translate(0, -65%) rotate(45deg);
  width: 8px;
}

.combo.open::after{
    transform: rotate(225deg);
}

.combo-input {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  display: block;
  font-size: 16px;
  padding: 8px 14px 8px;
  text-align: left;
  width: 100%;
  min-width: 160px;
}

.open .combo-input {
  border-radius: 4px 4px 0 0;
}

.combo-input:focus {
  border-color: #0067b8;
  box-shadow: 0 0 4px 2px #0067b8;
  outline: 4px solid transparent;
}

.combo-label {
  display: block;
  font-weight: 500;
  margin-right: 15px;
  line-height: 1.5rem;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  opacity: 0.65;
  font-size: 12px;
}

.combo-menu {
  background-color: #f5f5f5;
  border: 1px solid rgb(0 0 0 / 75%);
  border-radius: 0 0 4px 4px;
  display: none;
  max-height: 300px;
  overflow: auto;
  left: 0;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 100;
}

.open .combo-menu {
  display: block;
}

.combo-option {
  padding: 10px 12px 12px;
}

.combo-option:hover {
  color: #f3f3f3;
  background: #4c4c4c;
}

.combo-option.option-current {
  outline: 3px solid #0067b8;
  outline-offset: -3px;
}

</style>